import { Base64Utils } from '@dreamcommerce/utilities';
import Features from '@core/tools/features';
import { JsonUtils } from '@dreamcommerce/utilities';
import { CSS_NONE_CLASS } from '@core/constants/css_classes';

(function (undefined) {
    Shop.StockHandler.include({
        options: {
            productId: null,
            stockId: null,

            selectors: {
                productId: '#box_productfull input[type="hidden"][name="product_id"]',
                stockId: '#box_productfull input[type="hidden"][name="stock_id"]',

                trueStock: '.option_truestock',
                virtualFile: 'div.stocks .option_file input[type=file]',
                virtualText: 'div.stocks .option_text input[type=text]',
                virtualRadio: 'div.stocks .option_radio:not(.option_truestock) input[type=radio]',
                virtualCheckbox: 'div.stocks .option_checkbox input[type=checkbox]',
                virtualSelect: 'div.stocks .option_select:not(.option_truestock) select',
                virtualColor: 'div.stocks .option_color:not(.option_truestock) select',

                basketform: '#box_productfull form.form-basket fieldset.addtobasket-container',
                availContainer: '#box_productfull .availability > .row',
                ddavailability: '#box_productfull .availability .availability',
                dddelivery: '#box_productfull .availability .delivery .first',
                dtdelivery: '#box_productfull .availability .delivery .second',

                price: '#box_productfull .maininfo div.price',
                nettoprice: '#box_productfull .maininfo div.price-netto',
                unitPriceContainer: '#box_productfull .unit-price-container.gross',
                unitPriceNettoContainer: '#box_productfull .unit-price-container.net',

                exchangecheck: '#box_productfull .form-basket.loyaltyexchange',
                addtofav: '#box_productfull a.addtofav',
                loyaltypts: '#box_productfull .loyalty_points .points',

                availabilitynotifier: '#box_productfull form.form-basket fieldset.availability-notifier-container',
                availabilitynotifier_btn: '#box_productfull form.form-basket button.availability-notifier-btn',
                availabilitynotifier_unsub: '#box_productfull form.form-basket fieldset.availability-notifier-unsubscribe-container',
                availabilitynotifier_btn_unsub: '#box_productfull form.form-basket button.availability-notifier-unsubscribe-btn',

                productimg: '#box_productfull .productimg .productimg',
                productPhoto: '#box_productfull .productimg img',
                productTags: '#box_productfull .tags',
                smallgallery: '#box_productfull .smallgallery a img',
                unit: '#box_productfull form.form-basket span.unit',
                code: '#box_productfull .code span'
            },

            urls: {
                getStockOptions: 'product/getstockoptions/product/{productId}',
                getStock: 'product/getstock/product/{productId}/imgwidth/{imgwidth}/imgheight/{imgheight}/',
                getVirtualStock: 'product/getstock/stock/{productId}/imgwidth/{imgwidth}/imgheight/{imgheight}/'
            },

            stock: {
                defaulty: null,
                configuration: null
            },

            elements: {
                trueStock: null
            }
        },

        virt: null,
        stockOpt: null,
        downloaded: [],
        downloadedStock: {},
        productStocksCache: {},
        isInSettingDefaultProgress: false,

        initialize: function (options) {
            var self;
            var shop;
            var soptionLength;
            var soption;

            this.constructor.setOptions(options);
            self = this;
            shop = this.parent;

            if ($(this.options.selectors.stockId).length <= 0) {
                return false;
            }

            if ($(this.options.selectors.productId).length <= 0) {
                $('<input />', {
                    type: 'hidden',
                    value: $('body').attr('id').replace('shop_product', ''),
                    name: 'product_id'
                }).insertAfter($(this.options.selectors.stockId));
            }

            this.options.productId = parseInt($(this.options.selectors.productId).val(), 10);
            this.options.stockId = parseInt($(this.options.selectors.stockId).val(), 10);

            if (Shop.exist(Shop.values.OptionsConfiguration)) {
                this.options.stock.configuration = JSON.parse(Shop.values.OptionsConfiguration);
                soptionLength = this.options.stock.configuration ? this.options.stock.configuration.length : 0;
            }

            if (Shop.exist(Shop.values.OptionsDefault)) {
                this.options.stock.defaulty = JSON.parse(shop.base64.decode(Shop.values.OptionsDefault));
            }

            if (Features.isEnabled('stock_optimization')) {
                if (Shop.exist(Shop.values.ProductStocksCache)) {
                    this.productStocksCache = JsonUtils.fromJson(Base64Utils.decode(Shop.values.ProductStocksCache));
                }
            }

            this.options.elements.trueStock = $('.option_truestock');
            this.colorStockHandler();

            /* stock select/radio */
            $(this.options.selectors.trueStock + ' option').each(function () {
                var $parent;
                var value;

                $parent = $(this).parent()[0];
                value = parseInt($(this).val(), 10);

                if (self.options.stock.configuration.indexOf(value) < 0) {
                    if (!$parent.stockOptions) {
                        $parent.stockOptions = {};
                    }
                    $parent.stockOptions[value] = $(this).detach();
                }
            });

            $(
                this.options.selectors.trueStock +
                    ' .radio-wrap, ' +
                    this.options.selectors.trueStock +
                    ' .radio-wrap + label,' +
                    this.options.selectors.trueStock +
                    ' .radio-wrap + label + br'
            ).addClass('none');
            $(this.options.selectors.trueStock + ' select').val('');
            $(this.options.selectors.trueStock + ' select, .option_truestock .radio-wrap input').on('change', this, function (ev) {
                var self;
                var index;

                self = ev.data;

                if (this.tagName === 'SELECT') {
                    index = $(self.options.selectors.trueStock).index($(this).parent());
                    $(self.options.selectors.trueStock + ':gt(' + index + ')')
                        .find('option')
                        .each(function () {
                            var $parent;
                            var value;

                            $parent = $(this).parent()[0];
                            value = parseInt($(this).val(), 10);

                            if (!$parent.stockOptions) {
                                $parent.stockOptions = {};
                            }
                            $parent.stockOptions[value] = $(this).detach();
                        });
                } else {
                    index = $(self.options.selectors.trueStock).index($(this).parents(self.options.selectors.trueStock));
                }

                $(self.options.selectors.trueStock + ':gt(' + index + ')')
                    .find('select')
                    .val('');
                $(self.options.selectors.trueStock + ':gt(' + index + ')')
                    .find('input')
                    .attr('checked', false);
                $(self.options.selectors.trueStock + ':gt(' + index + ')')
                    .find('.radio-wrap, .radio-wrap + label, .radio-wrap + label + br, .stock-color a')
                    .addClass('none');

                if (!self.isInSettingDefaultProgress) {
                    self.getStockOptions(index, true, $(this).val());
                }
            });

            for (soption = 0; soption < soptionLength; soption++) {
                $(this.options.elements.trueStock[0])
                    .find('[value="' + this.options.stock.configuration[soption] + '"]')
                    .parent('.radio-wrap')
                    .removeClass('none');
                $(this.options.elements.trueStock[0])
                    .find('[value="' + this.options.stock.configuration[soption] + '"]')
                    .parent('.radio-wrap')
                    .next()
                    .removeClass('none');
                $(this.options.elements.trueStock[0])
                    .find('[value="' + this.options.stock.configuration[soption] + '"]')
                    .parent('.radio-wrap')
                    .next()
                    .next()
                    .removeClass('none');
                $(this.options.elements.trueStock[0])
                    .find('[data-value="' + this.options.stock.configuration[soption] + '"]')
                    .removeClass('none');
            }

            if (this.options.stock.defaulty && this.options.stock.defaulty.length > 0) {
                this.setDefault();
            } else {
                $('<option />', {
                    text: Shop.lang.common.product_stock_select
                }).prependTo($(this.options.elements.trueStock[0]).filter('.option_select').find('> select'));
            }

            this.virtualStockHandler();
        },

        getStockOptions: function (containerIndex, sync) {
            var self;
            var shop;
            var selectedOptions;
            var url;
            var complete;
            var key;

            $(this.options.elements.trueStock[containerIndex + 1]).addClass('loading');

            shop = this.parent;
            selectedOptions = [];
            complete = true;
            if (sync === undefined) {
                sync = true;
            }

            $('.option_truestock select, .option_truestock input').each(function () {
                var value;

                value = $(this).val();

                if (value) {
                    if (($(this).is(':visible') && $(this).is(':checked') && $(this).is(':radio')) || !$(this).is(':radio')) {
                        selectedOptions.push(parseInt(value, 10));
                    }
                }
            });

            if (selectedOptions.length !== this.options.elements.trueStock.length) {
                complete = false;
            }

            if (complete) {
                this.getStock(true, JSON.stringify(selectedOptions));
                return true;
            } else {
                url = shop.url(
                    shop.substitute(this.options.urls.getStockOptions, {
                        productId: this.options.productId
                    })
                );
            }

            self = this;
            key = parseInt(selectedOptions.join(''), 10);
            if (this.downloaded[containerIndex] && this.downloaded[containerIndex][key]) {
                self.filter(this.downloaded[containerIndex][key], containerIndex);
                return true;
            }

            const method = Features.isEnabled('stock_optimization') ? 'get' : 'post';

            $.ajax({
                url: url,
                async: sync,
                cache: true,
                method,
                data: {
                    options: JSON.stringify(selectedOptions)
                }
            }).success(function (json) {
                if (!self.downloaded[containerIndex]) {
                    self.downloaded[containerIndex] = [];
                }

                self.downloaded[containerIndex][key] = json;
                self.filter(json, containerIndex);
            });
        },

        filter: function (path, containerIndex) {
            var option;
            var optionLength;
            var $stockSelect;
            var $container;

            optionLength = path.length;
            $container = $(this.options.elements.trueStock[containerIndex + 1]);
            $stockSelect = $container.filter('.option_select, .option_color').find('> select');
            $stockSelect.empty();

            for (option = 0; option < optionLength; option++) {
                if ($container.hasClass('option_select') || $container.hasClass('option_color')) {
                    $stockSelect[0].stockOptions[path[option]].appendTo($stockSelect);
                }
                $container
                    .find('[value="' + path[option] + '"]')
                    .parent('.radio-wrap')
                    .removeClass('none');
                $container
                    .find('[value="' + path[option] + '"]')
                    .parent('.radio-wrap')
                    .next()
                    .removeClass('none');
                $container
                    .find('[value="' + path[option] + '"]')
                    .parent('.radio-wrap')
                    .next()
                    .next()
                    .removeClass('none');
                $container.find('[data-value="' + path[option] + '"]').removeClass('none');
            }

            if ($stockSelect[0] && $stockSelect.find('option').length > 1) {
                $($stockSelect[0]).find('option:not([value])').remove();
                $stockSelect[0].choose = $('<option />', {
                    text: Shop.lang.common.product_stock_select
                }).prependTo($stockSelect);

                $stockSelect.find('option').attr('selected', false);
                $stockSelect[0].choose.attr('selected', true);
            } else {
                $stockSelect.trigger('change');
            }

            $container.removeClass('loading');
        },

        setDefault: function () {
            this.isInSettingDefaultProgress = true;
            this.options.stock.defaulty.forEach(
                function (partStock, index) {
                    $(this.options.elements.trueStock[index]).find('select').val(partStock);
                    $(this.options.elements.trueStock[index])
                        .find('input[value="' + partStock + '"]')
                        .attr('checked', true);
                    $(this.options.elements.trueStock[index])
                        .find('[data-value="' + partStock + '"]')
                        .addClass('current');
                    this.getStockOptions(index, false);
                }.bind(this)
            );
            this.isInSettingDefaultProgress = false;
        },

        colorStockHandler: function () {
            var colorStock;

            colorStock = $('.stocks .option_color');
            colorStock.each(function () {
                var trueStock;
                var $select;
                var $options;
                var $colorContainer;

                trueStock = $(this).hasClass('option_truestock');
                $select = $(this).find('> select');
                $select.addClass('none');

                $colorContainer = $('<div />', {
                    class: 'stock-color'
                });

                $options = $select
                    .find('option')
                    .filter(function () {
                        return $(this).val().length > 0;
                    })
                    .each(function () {
                        var $a;

                        $a = $('<a />', {
                            class: $(this).is(':selected') ? 'current none' : 'none',
                            title: $(this).text(),
                            'data-value': $(this).val()
                        })
                            .css('background-color', $(this).attr('title'))
                            .appendTo($colorContainer);

                        if (!trueStock) {
                            $a.removeClass('none');
                        }

                        $a.on('click', this, function (ev) {
                            var $option;

                            $option = $(ev.data);
                            $colorContainer.find('a').removeClass('current');
                            $(this).addClass('current');

                            $select.val($option.val()).trigger('change');
                        });
                    });

                $colorContainer.appendTo($(this));
            });
        },

        virtualStockHandler: function () {
            var self;
            var timeoutId;

            self = this;

            // virtual file
            $(this.options.selectors.virtualFile).on('change', function () {
                self.downloadVirtualStock(this, true);
            });

            // virtual text
            timeoutId = 0;
            $(this.options.selectors.virtualText).on('keydown', function () {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(
                    function () {
                        self.downloadVirtualStock(this, true);
                    }.bind(this),
                    100
                );
            });

            // virtual radio
            $(this.options.selectors.virtualRadio).on('change', function () {
                self.downloadVirtualStock(this);
            });

            // virtual checkbox
            $(this.options.selectors.virtualCheckbox).on('change', function () {
                self.downloadVirtualStock(this, true);
            });

            // virtual select
            $(this.options.selectors.virtualSelect).on('change', function () {
                self.downloadVirtualStock(this);
            });

            // virtual color
            $(this.options.selectors.virtualColor).on('change', function () {
                self.downloadVirtualStock(this);
            });
        },

        downloadVirtualStock: function (current, noImg) {
            var sId;
            var virt = [];
            var currentVirt = [];
            var shopClass = this.parent;

            sId = parseInt($(this.options.selectors.stockId).val().replace(/\s/g, ''));

            if (current && $(current).length > 0) {
                currentVirt = $(current).attr('name').replace('option_', '') + '=' + $(current).val();
            }

            $(this.options.selectors.virtualColor).each(function () {
                if ($(this).val().length > 0) {
                    virt.push($(this).attr('name').replace('option_', '') + '=' + $(this).val());
                }
            });

            $(this.options.selectors.virtualFile).each(function () {
                if ($(this).val().length > 0) {
                    virt.push($(this).attr('name').replace('option_', '') + '=1');
                }
            });

            $(this.options.selectors.virtualText).each(function () {
                if ($(this).val().length > 0) {
                    virt.push($(this).attr('name').replace('option_', '') + '=1');
                }
            });

            $(this.options.selectors.virtualRadio).each(function () {
                if ($(this).is(':checked')) {
                    virt.push($(this).attr('name').replace('option_', '') + '=' + $(this).val());
                }
            });

            $(this.options.selectors.virtualCheckbox).each(function () {
                if ($(this).is(':checked')) {
                    virt.push($(this).attr('name').replace('option_', '') + '=' + $(this).val());
                }
            });

            $(this.options.selectors.virtualSelect).each(function () {
                if ($(this).val().length > 0) {
                    virt.push($(this).attr('name').replace('option_', '') + '=' + $(this).val());
                }
            });

            if (virt.length > 0) {
                virt = virt.join('&');
            } else {
                virt = 'default';
            }

            this.virt = virt;
            this.getStock(false, null, currentVirt);
        },

        getStock: function (isStock, options, currentVirt) {
            var self;
            var shop;
            var url;
            var key;
            var $addToBasket;

            self = this;
            shop = this.parent;

            if (currentVirt === undefined) {
                currentVirt = '';
            }

            if (isStock) {
                url = shop.url(
                    shop.substitute(this.options.urls.getStock, {
                        productId: this.options.productId,
                        imgwidth: Shop.values.OptionImgWidth,
                        imgheight: Shop.values.OptionImgHeight
                    })
                );
            } else {
                url = shop.url(
                    shop.substitute(this.options.urls.getVirtualStock, {
                        productId: this.options.stockId,
                        imgwidth: Shop.values.OptionImgWidth,
                        imgheight: Shop.values.OptionImgHeight
                    })
                );
            }

            if (this.virt) {
                url += '?virt=' + escape(this.virt) + '&currentVirt=' + escape(currentVirt);
            }

            if (options === null && this.stockOpt !== null) {
                options = this.stockOpt;
            }

            if (options) {
                if (this.virt !== null) {
                    key = shop.base64.encode(options + escape(this.virt) + escape(currentVirt));
                } else {
                    key = shop.base64.encode(options + escape(currentVirt));
                }
            } else {
                key = shop.base64.encode(escape(this.virt) + escape(currentVirt));
            }

            this.stockOpt = options;

            if (Features.isEnabled('stock_optimization')) {
                if (options && this.virt === null) {
                    const keySorted = JsonUtils.fromJson(options).sort().join(',');

                    if (this.productStocksCache[keySorted]) {
                        this.stock = this.productStocksCache[keySorted];
                        this.display(isStock);
                        return true;
                    }
                }
            }

            if (this.downloadedStock[key]) {
                this.stock = this.downloadedStock[key];
                this.display(isStock);
                return true;
            }

            const method = Features.isEnabled('stock_optimization') ? 'get' : 'post';
            $addToBasket = $(self.options.selectors.basketform).find('.addtobasket');
            $.ajax({
                url: url,
                async: true,
                cache: true,
                method,
                data: {
                    options: options
                },

                beforeSend: function () {
                    if (isStock) {
                        $addToBasket.attr('disabled', true);
                    }
                },

                success: function (json) {
                    self.stock = json;
                    self.downloadedStock[key] = json;
                    self.display(isStock);

                    if (isStock) {
                        $addToBasket.attr('disabled', false);
                    }
                }
            });
        },

        display: function (isStock) {
            var shop;
            var price;
            var atd;
            var dtd;
            var dtr;
            var pdiv;
            var ndiv;
            var availabilityNotifier;
            var availabilityNotifierUnsub;
            var basketForm;
            var availableBtn;
            var availableBtnUnsub;
            var $unitPrice;
            var $unit;

            var loyaltyPrice;

            if (this.stock && this.stock.sid) {
                shop = this.parent;

                if (this.stock.price) {
                    price = parseFloat(
                        this.stock.price
                            .replace(/,/g, '.')
                            .replace(/\s/g, '')
                            .replace(/&nbsp;/g, '')
                    );

                    if ($('#mraty-calc').length > 0 && window.mBank) {
                        if (price >= 300) {
                            $('#mraty-calc').removeClass('none');
                            mBank(window, document, price);
                            mBank.init();
                            mBank.mRaty.reopenMBank();
                        } else {
                            $('#mraty-calc').addClass('none');
                            mBank.mRaty.closeMBank();
                        }
                    }
                }

                if (this.stock.historical_lowest_price && $('.js__omnibus-price').length > 0) {
                    $('#box_productfull .js__omnibus-price').text(this.stock.historical_lowest_price);
                }

                if (this.stock.historical_lowest_price && $('.js__omnibus-price-gross').length > 0) {
                    $('#box_productfull .js__omnibus-price-gross').text(this.stock.historical_lowest_price);
                }

                if (this.stock.net_historical_lowest_price && $('.js__omnibus-price-net').length > 0) {
                    $('#box_productfull .js__omnibus-price-net').text(this.stock.net_historical_lowest_price);
                }

                this.options.stockId = this.stock.sid;

                if (isStock) {
                    $(this.options.selectors.stockId).val(this.stock.sid);
                    (availableBtn = $(this.options.selectors.availabilitynotifier_btn)),
                        (availableBtnUnsub = $(this.options.selectors.availabilitynotifier_btn_unsub));

                    if (availableBtn) {
                        availableBtn.attr('data-stock-id', this.stock.sid);
                    }
                    if (availableBtnUnsub) {
                        availableBtnUnsub.attr('data-stock-id', this.stock.sid);
                    }
                }

                this.updateProductCardImages();

                // Availability change
                atd = $(this.options.selectors.ddavailability);

                if (atd.length > 0) {
                    $(this.options.selectors.ddavailability).empty();

                    if (this.stock.availability_photo !== false) {
                        $('<img />').attr('src', this.stock.availability_photo).attr('alt', this.stock.availability).appendTo(atd);
                    }

                    $('<span />', {
                        class: 'first',
                        text: Shop.lang.quickview.availability + ':'
                    }).appendTo($(this.options.selectors.ddavailability));

                    $('<span />', {
                        class: 'second',
                        text: this.stock.availability
                    }).appendTo($(this.options.selectors.ddavailability));
                }

                // Shipping change
                dtd = $(this.options.selectors.dddelivery);
                dtr = $(this.options.selectors.dtdelivery);

                if (this.stock.delivery) {
                    if (dtd.length > 0 && dtr.length > 0) {
                        dtr.text(this.stock.delivery);
                    }
                }

                // Prices
                pdiv = $(this.options.selectors.price).last();
                ndiv = $(this.options.selectors.nettoprice).last();

                if ($(this.options.selectors.exchangecheck).length > 0) {
                    if (pdiv.length > 0) {
                        if (this.stock.loyalty_currency_price_float > 0) {
                            loyaltyPrice = this.stock.loyaltyprice + ' + ' + this.stock.loyalty_currency_price;
                        } else {
                            loyaltyPrice = this.stock.loyaltyprice;
                        }

                        pdiv.find('em').removeClass('color').text(loyaltyPrice);
                        pdiv.find('del').addClass('none').text('');
                    }

                    if (ndiv.length > 0) {
                        ndiv.find('em').addClass('none').text('');
                    }
                } else {
                    if (Shop.exist(pdiv)) {
                        if (this.stock.special_offer == false) {
                            pdiv.find('.main-price').removeClass('color').text(this.stock.price);
                            if (this.stock.currency_price) {
                                pdiv.find('.default-currency').text('(' + this.stock.currency_price + ')');
                            }

                            if (Features.isEnabled('special_offers_stocks_v1')) {
                                pdiv.find('.price__regular').addClass(CSS_NONE_CLASS);
                                pdiv.find('.js__omnibus-price-container').addClass(CSS_NONE_CLASS);
                            } else {
                                pdiv.find('del').addClass('none').text('');
                            }
                        } else {
                            pdiv.find('.main-price').addClass('color').text(this.stock.special_offer);
                            if (this.stock.currency_special_offer) {
                                pdiv.find('.default-currency').text('(' + this.stock.currency_special_offer + ')');
                            }

                            if (Features.isEnabled('special_offers_stocks_v1')) {
                                pdiv.find('.price__regular .price__inactive').text(this.stock.price);
                                pdiv.find('.price__regular').removeClass(CSS_NONE_CLASS);
                                pdiv.find('.js__omnibus-price-container').removeClass(CSS_NONE_CLASS);
                            } else {
                                pdiv.find('del').removeClass('none').text(this.stock.price);
                            }
                        }
                    }

                    if (Shop.exist(ndiv)) {
                        if (this.stock.net_special_offer == false) {
                            ndiv.find('.main-price').text(this.stock.net_price);
                            if (this.stock.net_currency_price) {
                                ndiv.find('.default-currency').text('(' + this.stock.net_currency_price + ')');
                            }

                            if (Features.isEnabled('special_offers_stocks_v1')) {
                                ndiv.find('.price__regular').addClass(CSS_NONE_CLASS);
                                ndiv.find('.js__omnibus-price-container').addClass(CSS_NONE_CLASS);
                            }
                        } else {
                            ndiv.find('.main-price').text(this.stock.net_special_offer);
                            if (this.stock.net_currency_special_offer) {
                                ndiv.find('.default-currency').text('(' + this.stock.net_currency_special_offer + ')');
                            }

                            if (Features.isEnabled('special_offers_stocks_v1')) {
                                ndiv.find('.price__regular .price__inactive').text(this.stock.net_price);
                                ndiv.find('.price__regular').removeClass(CSS_NONE_CLASS);
                                ndiv.find('.js__omnibus-price-container').removeClass(CSS_NONE_CLASS);
                            } else {
                                ndiv.find('del').text(this.stock.net_price);
                            }
                        }
                    }
                }

                //Unit price
                if ($(this.options.selectors.unitPriceContainer).length > 0) {
                    $unitPrice = $(this.options.selectors.unitPriceContainer).find('.price');
                    $unit = $(this.options.selectors.unitPriceContainer).find('.unit');

                    if ($unitPrice.length > 0) {
                        if (this.stock.special_offer_unit_calculation) {
                            $unitPrice.text(this.stock.special_offer_unit_calculation);
                        } else {
                            $unitPrice.text(this.stock.price_unit_calculation);
                        }
                    }

                    if ($unit.length > 0) {
                        $unit.text(this.stock.unit_calculation_unit);
                    }
                }

                if ($(this.options.selectors.unitPriceNettoContainer).length > 0) {
                    $unitPrice = $(this.options.selectors.unitPriceNettoContainer).find('.price');
                    $unit = $(this.options.selectors.unitPriceNettoContainer).find('.unit');

                    if ($unitPrice.length > 0) {
                        if (this.stock.net_special_offer_unit_calculation) {
                            $unitPrice.text(this.stock.net_special_offer_unit_calculation);
                        } else {
                            $unitPrice.text(this.stock.net_price_unit_calculation);
                        }
                    }

                    if ($unit.length > 0) {
                        $unit.text(this.stock.unit_calculation_unit);
                    }
                }

                $(this.options.selectors.addtofav).each(
                    function (self) {
                        $(this).attr(
                            'href',
                            $(this)
                                .attr('href')
                                .replace(/\/\w+$/, '/') + self.stock.sid
                        );
                    },
                    [this]
                );

                $(this.options.selectors.loyaltypts).each(
                    function (self) {
                        $(this).text(self.stock.loyaltypts_float);

                        if (self.stock.loyaltypts_float > 0) {
                            $(this).parent().removeClass('none');
                        } else {
                            $(this).parent().addClass('none');
                        }
                    },
                    [this]
                );

                availabilityNotifier = $(this.options.selectors.availabilitynotifier);
                availabilityNotifierUnsub = $(this.options.selectors.availabilitynotifier_unsub);
                basketForm = $(this.options.selectors.basketform);

                if (this.stock.can_buy == true) {
                    basketForm.removeClass('none');

                    if (availabilityNotifier) {
                        availabilityNotifier.addClass('none');
                    }

                    if (availabilityNotifierUnsub) {
                        availabilityNotifierUnsub.addClass('none');
                    }
                } else {
                    basketForm.addClass('none');

                    if (this.stock.is_subscribed) {
                        if (availabilityNotifierUnsub) {
                            availabilityNotifierUnsub.removeClass('none');
                        }
                        if (availabilityNotifier) {
                            availabilityNotifier.addClass('none');
                        }
                    } else {
                        if (availabilityNotifier) {
                            availabilityNotifier.removeClass('none');
                        }
                        if (availabilityNotifierUnsub) {
                            availabilityNotifierUnsub.addClass('none');
                        }
                    }
                }

                $(this.options.selectors.unit).text(this.stock.unit);
                $(this.options.selectors.code).text(this.stock.code);

                this.parent.fireEvent('stock:change', this.stock);

                this.updateProductAvailability();
            }
        },

        updateProductCardImages: function () {
            if (this.stock.photo !== false) {
                const shop = this.parent;
                const $mainImage = $(this.options.selectors.productimg)[0];
                const stockPhotoName = this.stock.photo.substr(this.stock.photo.lastIndexOf('/') + 1);

                if ($mainImage) {
                    $($mainImage).attr('src', this.stock.photo);
                    $($mainImage).data('photo_orig', this.stock.photo_orig);

                    const previouslySelectedImageId = $($mainImage)
                        .attr('class')
                        .replace(/.*gallery_([0-9]+).*/, '$1');

                    const doesMainImageHasIdInClass =
                        $($mainImage).hasClass('gallery_' + previouslySelectedImageId) ||
                        $($mainImage).hasClass('gallery_' + previouslySelectedImageId + '-mini');

                    if (doesMainImageHasIdInClass) {
                        $($mainImage)
                            .removeClass('gallery_' + previouslySelectedImageId + '-mini')
                            .removeClass('gallery_' + previouslySelectedImageId)
                            .addClass('gallery_' + this.stock.photo_id);
                    }

                    if ($(this.options.selectors.smallgallery).length > 0) {
                        let counter = 0;

                        $(this.options.selectors.smallgallery).each(
                            function (self) {
                                let altString;
                                const doesStockHasOwnPhoto = self.stock.photo.indexOf('productGfx_' + self.stock.photo_id + '/') >= 0;

                                if (doesStockHasOwnPhoto) {
                                    const isMainImageThumbnail =
                                        $(this)
                                            .attr('data-img-name')
                                            .indexOf('productGfx_' + self.stock.photo_id + '/') > 0;

                                    if (isMainImageThumbnail) {
                                        const previouslySelectedThumbnailAnchor = document.querySelector('a.current.js__gallery-anchor-image');
                                        previouslySelectedThumbnailAnchor?.classList.remove('current');

                                        $(this).trigger('mouseenter');
                                        $($mainImage).removeClass('non-stock-img');
                                    }
                                } else {
                                    altString = $(this)
                                        .attr('data-img-name')
                                        .substr($(this).attr('data-img-name').lastIndexOf('/') + 1);
                                    if (stockPhotoName.indexOf(altString) != -1) {
                                        const previouslySelectedThumbnailAnchor = document.querySelector('a.current.js__gallery-anchor-image');
                                        previouslySelectedThumbnailAnchor?.classList.remove('current');

                                        $(this).trigger('mouseenter');
                                        $($mainImage).removeClass('non-stock-img');
                                    }
                                }

                                if (!(stockPhotoName.indexOf(altString) != -1) && !(self.stock.photo.indexOf(self.stock.photo_id) >= 0)) {
                                    counter++;

                                    if (counter == $('.innersmallgallery li').length) {
                                        $(self.options.selectors.smallgallery).parent().removeClass('current');
                                        $($mainImage).addClass('non-stock-img');

                                        $($mainImage)
                                            .off('click')
                                            .on('click', function (ev) {
                                                var img;

                                                ev.stopPropagation();
                                                ev.preventDefault();

                                                img = $('.innersmallgallery img[data-img-name="' + $(this).attr('src') + '"]');
                                                if (img.length > 0) {
                                                    img.trigger('click');
                                                }
                                            });
                                    }
                                }
                            },
                            [this]
                        );
                    } else {
                        if ($('#box_productgallery').length > 0) {
                            $($mainImage).addClass('non-stock-img');
                        }
                    }

                    if (Shop.exist(shop.classes.ImageZoom)) {
                        shop.subclass('ImageZoom');
                        shop.imagezoom.reset();
                    }
                }
            }

            if (Features.isEnabled('special_offers_stocks_v1')) {
                let productTags = document.querySelector(this.options.selectors.productTags);

                if (!productTags) {
                    productTags = document.createElement('ul');
                    productTags.classList.add('tags');

                    document.querySelector(this.options.selectors.productPhoto)?.after(productTags);
                }

                const promoTag = productTags?.querySelector('.promo');

                if (this.stock.special_offer == false) {
                    promoTag?.classList.add(CSS_NONE_CLASS);
                } else {
                    promoTag?.classList.remove(CSS_NONE_CLASS);

                    if (!promoTag) {
                        const promo = document.createElement('li');

                        promo.classList.add('promo');
                        promo.textContent = Shop.lang.common.discount;
                        productTags.prepend(promo);
                    }
                }
            }
        },

        updateProductAvailability() {
            if (this.stock && this.stock.visibility) {
                const isAdditionalTextVisible = this.stock.visibility.additional_text === 1;
                const isTitleAndGalleryGrayedOut = this.stock.visibility.gallery_and_name_gray === 1;

                this.toggleAdditionalText(isAdditionalTextVisible);
                this.toggleGrayOnProductTitle(isTitleAndGalleryGrayedOut);
                this.toggleGrayOnGallery(isTitleAndGalleryGrayedOut);
            }
        },

        toggleAdditionalText(isEnabled) {
            const $productUnavailableText = $('.js__product-availability');

            if (isEnabled) {
                $productUnavailableText.removeClass('none');
            } else {
                $productUnavailableText.addClass('none');
            }
        },

        toggleGrayOnProductTitle(isGrayedOut) {
            const $productTitle = $('h1.name');

            if (isGrayedOut) {
                $productTitle.addClass('product_inactive');
            } else {
                $productTitle.removeClass('product_inactive');
            }
        },

        toggleGrayOnGallery(isGrayedOut) {
            const $galleryWrapper = $('div.productimg');

            if (isGrayedOut) {
                $galleryWrapper.addClass('product_inactive');
            } else {
                $galleryWrapper.removeClass('product_inactive');
            }
        }
    });
})();
