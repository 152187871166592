import { TGoogleAnalyticsV4GtagEvent, TGoogleAnalyticsV4GtagOptions } from './types';

import { AnalyticService } from '@frontstoreRwd/modules/analytics/classes/service';
import { GtagSDK } from './sdk';
import { TServiceName } from '../../types';
import eventsHandlersMapper from './events';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy/index';
import { CONSENTS_SAVED } from '@frontstoreRwd/modules/consents/classes/consents_api/event_names';
import { ConsentsGroup } from '@frontstoreRwd/modules/consents/classes/consents_group';
import { $on } from '@core/tools/event_bus';
import { CONSENT_NAMES } from '@storefrontRoot/models/consent/consent_constants';

export class GoogleAnalyticV4GtagService extends AnalyticService<GoogleAnalyticV4GtagService> {
    private _dataLayerName = 'dataLayer';
    private _options: TGoogleAnalyticsV4GtagOptions;
    private _gtag: (...args: unknown[]) => void = () => undefined;
    protected _events: TGoogleAnalyticsV4GtagEvent = eventsHandlersMapper;

    constructor(name: TServiceName, options: TGoogleAnalyticsV4GtagOptions) {
        super(name);
        this._options = options;
    }

    public async setup(): Promise<void> {
        const layerName = this._options.layerName || this._dataLayerName;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window[layerName] = window[layerName] || [];
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        function gtag(...args: unknown[]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line prefer-rest-params
            window[layerName].push(arguments);
        }

        this._gtag = gtag;
        gtag('js', new Date());

        if (customerPrivacy.isEnabled()) {
            this._setDefaultConsents();
            this._updateConsents();
        }

        this._options.trackingId.forEach((trackingId) => {
            const isGoogleRemarketingConversionsActive =
                this._options.googleRemarketing?.isRemarketingActive && this._options.googleRemarketing?.isConversionEnabled;

            if (isGoogleRemarketingConversionsActive) {
                gtag('config', trackingId, { allow_enhanced_conversions: true });
            } else {
                gtag('config', trackingId);
            }
        });

        await GtagSDK.create(this._options.trackingId[0], layerName);
        this._isSetuped = true;
    }

    private _setDefaultConsents() {
        const defaultConsents = {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied'
        };

        customerPrivacy.onAnalyticsConsentGranted(() => {
            defaultConsents.analytics_storage = 'granted';
        });

        customerPrivacy.onMarketingConsentGranted(() => {
            defaultConsents.ad_storage = 'granted';
            defaultConsents.ad_user_data = 'granted';
            defaultConsents.ad_personalization = 'granted';
        });

        this._gtag('consent', 'default', defaultConsents);
    }

    private _updateConsents(): void {
        $on(CONSENTS_SAVED, (consentsGroup: ConsentsGroup) => {
            const grantedNames = consentsGroup.getGranted().map((consent) => consent.getName());

            const consents = {
                ad_storage: grantedNames.indexOf(CONSENT_NAMES.marketingConsent) > -1 ? 'granted' : 'denied',
                ad_user_data: grantedNames.indexOf(CONSENT_NAMES.marketingConsent) > -1 ? 'granted' : 'denied',
                ad_personalization: grantedNames.indexOf(CONSENT_NAMES.marketingConsent) > -1 ? 'granted' : 'denied',
                analytics_storage: grantedNames.indexOf(CONSENT_NAMES.analyticsConsent) > -1 ? 'granted' : 'denied'
            };

            this._gtag('consent', 'update', consents);
        });
    }

    public getGtag(): (...args: unknown[]) => void {
        return this._gtag;
    }

    public getRemarketingOptions(): TGoogleAnalyticsV4GtagOptions['googleRemarketing'] {
        return this._options.googleRemarketing;
    }

    public getTrackingId(): string[] {
        return this._options.trackingId;
    }
}
