import { TGoogleAnalyticsV4GTMEvent, TGoogleAnalyticsV4GTMOptions } from './types';

import { AnalyticService } from '@frontstoreRwd/modules/analytics/classes/service';
import { TServiceName } from '../../types';
import eventsHandlersMapper from './events';
import { GoogleAnalyticsV4Map } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/mapper';
import { TGoogleAnalyticsV4GtagOptions } from '@frontstoreRwd/modules/analytics/services/google_analytics_v4/gtag/types';
import customerPrivacy from '@frontstoreRwd/modules/customer_privacy';
import { $on } from '@core/tools/event_bus';
import { CONSENTS_SAVED } from '@frontstoreRwd/modules/consents/classes/consents_api/event_names';
import { ConsentsGroup } from '@frontstoreRwd/modules/consents/classes/consents_group';
import { CONSENT_NAMES } from '@storefrontRoot/models/consent/consent_constants';

export class GoogleAnalyticV4GTMService extends AnalyticService<GoogleAnalyticV4GTMService> {
    private _options: TGoogleAnalyticsV4GTMOptions;
    private _layer: unknown[] = [];
    private _mapper = GoogleAnalyticsV4Map;

    protected _events: TGoogleAnalyticsV4GTMEvent = eventsHandlersMapper;
    private _gtag: (...args: unknown[]) => void = () => undefined;

    constructor(name: TServiceName, options: TGoogleAnalyticsV4GTMOptions) {
        super(name);
        this._options = options;
    }

    public async setup(): Promise<void> {
        const layerName = this._options.dataLayerName;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this._layer = window[layerName] || [];

        function gtag(...args: unknown[]) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            // eslint-disable-next-line prefer-rest-params
            window[layerName].push(arguments);
        }
        this._gtag = gtag;

        if (customerPrivacy.isEnabled()) {
            this._setDefaultConsents();
            this._updateConsents();
        }

        this._isSetuped = true;
    }

    private _setDefaultConsents() {
        const defaultConsents = {
            ad_storage: 'denied',
            ad_user_data: 'denied',
            ad_personalization: 'denied',
            analytics_storage: 'denied'
        };

        customerPrivacy.onAnalyticsConsentGranted(() => {
            defaultConsents.analytics_storage = 'granted';
        });

        customerPrivacy.onMarketingConsentGranted(() => {
            defaultConsents.ad_storage = 'granted';
            defaultConsents.ad_user_data = 'granted';
            defaultConsents.ad_personalization = 'granted';
        });

        this._gtag('consent', 'default', defaultConsents);
    }

    private _updateConsents(): void {
        $on(CONSENTS_SAVED, (consentsGroup: ConsentsGroup) => {
            const grantedNames = consentsGroup.getGranted().map((consent) => consent.getName());

            const consents = {
                ad_storage: grantedNames.indexOf(CONSENT_NAMES.marketingConsent) > -1 ? 'granted' : 'denied',
                ad_user_data: grantedNames.indexOf(CONSENT_NAMES.marketingConsent) > -1 ? 'granted' : 'denied',
                ad_personalization: grantedNames.indexOf(CONSENT_NAMES.marketingConsent) > -1 ? 'granted' : 'denied',
                analytics_storage: grantedNames.indexOf(CONSENT_NAMES.analyticsConsent) > -1 ? 'granted' : 'denied'
            };

            this._gtag('consent', 'update', consents);
        });
    }

    public getLayer(): unknown[] {
        return this._layer;
    }

    public getMapper(): typeof GoogleAnalyticsV4Map {
        return this._mapper;
    }

    public getRemarketingOptions(): TGoogleAnalyticsV4GtagOptions['googleRemarketing'] {
        return this._options.googleRemarketing;
    }

    public getTrackingId(): string[] {
        return this._layer.filter((obj: any) => obj && obj[0] && obj[0] === 'config' && obj[1].includes('G-')).map((obj: any) => obj[1]);
    }
}
